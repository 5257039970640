import * as React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { connect } from 'react-redux';
import { DsiLoginForm as LoginForm } from 'eshop-defaults';
import { withRouter, WithRouterProps } from 'react-router';
import { loginUser } from 'react-auth/lib/containers/Auth/actions';
import { UrlHashService } from '../../services/HashService';
import { onlyPublic } from '../../utilities/auth';
import { cartIdSelector, fetchCart, resetCart } from '../Cart/cartSlice';
import * as cookie from 'react-cookies';
import {
  BreadCrumbType,
  setBreadCrumbPath,
} from '../BreadCrumb/breadCrumbSlice';
import { connectSsr } from 'ssr-service';
import { hostnameSelector, langSelector } from '../App/selectors';
import MetaTags from '../../components/_helpers/MetaTags/MetaTags';
import { __ } from 'react-i18n';
import { CART_COOKIE_ID, removeCartCookie } from '../Cart/helpers';
import { push } from 'react-router-redux';
import {
  resolveBaseUrlByLang,
  resolveDomainByHostname,
} from '../../configureTrans';
import { prop } from '../../utilities';

interface Props {
  cartId: string | undefined;
  dispatch: (action: any) => void;
  lang: string;
  hostname: string;
}

interface State {
  error: string;
  baseUrl: string;
}

const Wrapper = styled.div`
  width: 100%;
  max-width: ${rem(400)};
  margin: ${rem(32)} auto;

  ${({ theme }) => theme.mediab.l925`
     padding: ${rem(16)};
     margin: 0 auto;
  `}
`;

class LoginPage extends React.Component<Props & WithRouterProps, State> {
  public static async getInitialProps(props) {}

  constructor(props) {
    super(props);

    this.state = {
      error: '',
      baseUrl: '',
    };
  }

  public componentDidMount() {
    this.props.dispatch(setBreadCrumbPath(BreadCrumbType.LOGIN, null));

    const { hash } = this.props.location;
    const urlHashService = new UrlHashService(hash.replace('#', ''));
    const accessToken = urlHashService.get('access_token');
    const register = urlHashService.get('register');
    const error = decodeURIComponent(urlHashService.get('error'));

    this.login(accessToken, error, register);

    this.setState({
      baseUrl: window?.location?.origin,
    });
  }

  public async login(accessToken, error, register) {
    if (accessToken) {
      removeCartCookie();
      await this.props.dispatch(
        loginUser(accessToken, false, () =>
          this.props.dispatch(resetCart(false)),
        ),
      );
      let cartCookie = prop(cookie, `${CART_COOKIE_ID}`);
      await this.props.dispatch(fetchCart(true, cartCookie));
      const queryParams: { name: string; value: string }[] = [];
      if (register) {
        queryParams.push({ name: 'r', value: '1' });
      }

      this.props.dispatch(
        push(
          `/${
            queryParams.length
              ? `?${queryParams
                  .map(param => `${param.name}=${param.value}`)
                  .join(`&`)}`
              : ''
          }`,
        ),
      );
    } else if (error) {
      this.setState({
        error: error && error !== 'null' ? error : '',
      });
    }
  }

  public render() {
    const {
      cartId,
      location: { pathname, search },
      lang,
      hostname,
    } = this.props;
    const { error } = this.state;

    if (!this.state.baseUrl) {
      const baseUrl = window?.location?.origin;
      if (baseUrl) {
        this.setState({
          baseUrl: baseUrl,
        });
      }
    }

    const baseUrl = resolveBaseUrlByLang(hostname, lang).slice(0, -1);

    return (
      <>
        <Wrapper>
          <MetaTags tags={{ title: __('Prihlásenie B2C') }} />
          <LoginForm
            baseApiUrl={process.env.REACT_APP_API_BASE_URL}
            baseUrl={baseUrl}
            cartId={cartId}
            pathname={pathname}
            search={search}
            error={error}
            lang={lang}
            domainId={+resolveDomainByHostname(hostname)}
            loginType={'b2c'}
          />
        </Wrapper>
      </>
    );
  }

  public handleFacebookLogin = () => {
    if (
      typeof window !== 'undefined' &&
      window &&
      window.location &&
      window.location.href
    ) {
      window.location.href = `${process.env.REACT_APP_API_BASE_URL}/oauth/facebook?state="${this.props.cartId}"`;
    }
  };

  public handleGoogleLogin = () => {
    if (
      typeof window !== 'undefined' &&
      window &&
      window.location &&
      window.location.href
    ) {
      window.location.href = `${process.env.REACT_APP_API_BASE_URL}/oauth/google?state="${this.props.cartId}"`;
    }
  };
}

const mapStateToProps = state => {
  return {
    cartId: cartIdSelector(state),
    lang: langSelector(state),
    hostname: hostnameSelector(state),
  };
};

export default connect(mapStateToProps)(LoginPage);
