import * as React from 'react';
import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import { MetaTags } from 'eshop-defaults';
import {
  customerInfoSelector,
  fetchDeliveryAddresses,
  deliveryAddressesSelector,
  deliveryAddressesIsFetchingSelector,
} from './myAccountSlice';
import MyDeliveryAddresses from '../../components/MyAccount/MyDeliveryAddresses';
import API, { ThenArg } from '../../services/API';
import {
  BreadCrumbType,
  setBreadCrumbPath,
} from '../BreadCrumb/breadCrumbSlice';
import { cartDataSelector, replaceCurrentCart } from '../Cart/cartSlice';

interface Props {
  user: any;
  dispatch: any;
  customerInfo: ThenArg<typeof API.getCustomerDetails>;
  deliveryAddresses: ThenArg<typeof API.getDeliveryAddresses>;
  isFetching: boolean;
  cart: any;
}

class MyDeliveryAddressesContainer extends React.Component<Props> {
  public static async getInitialProps(props) {
    const { dispatch } = props;
    try {
      dispatch(setBreadCrumbPath(BreadCrumbType.MY_ACCOUNT_DELIVERY_ADDRESSES));
      await dispatch(fetchDeliveryAddresses(true));
      return;
    } catch (exp) {
      // console.log(exp);
      return;
    }
  }

  public render() {
    const { user, deliveryAddresses, isFetching } = this.props;

    return (
      <>
        <MetaTags tags={{ title: 'Moj účet - Moje dodacie adresy' }} />
        <MyDeliveryAddresses
          isFetching={isFetching}
          userId={user && user.id}
          deliveryAddresses={deliveryAddresses}
          refreshAddresses={this.refreshAddresses}
          isB2B={false}
          isB2C={true}
          refreshCart={this.refreshCart}
        />
      </>
    );
  }

  public refreshAddresses = async () => {
    const { dispatch } = this.props;
    try {
      await dispatch(fetchDeliveryAddresses(true));
    } catch (exp) {
      // console.log(exp);
    }
  };

  public refreshCart = async () => {
    const { dispatch, cart } = this.props;

    if (cart?.id) {
      await dispatch(replaceCurrentCart(cart.id));
    }
  };
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    customerInfo: customerInfoSelector(state),
    deliveryAddresses: deliveryAddressesSelector(state),
    isFetching: deliveryAddressesIsFetchingSelector(state),
    cart: cartDataSelector(state),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'MyDeliveryAddressesContainer' })(
    MyDeliveryAddressesContainer,
  ),
);
