import React from 'react';
import { FlexCol, TextP, DsiSvgIcon as SvgIcon } from 'eshop-defaults';
import styled from '../../theme/styled-components';
import { connect } from 'react-redux';
import { __ } from 'react-i18n';
import { Link } from 'react-router';
import { rem } from 'polished';
import { IconType } from 'eshop-defaults/lib/components/Dsi/General/SvgIcon';
import { __r } from 'react-i18n/lib';
import { fetchCart, resetCart } from '../../containers/Cart/cartSlice';
import { logoutUser } from 'react-auth/lib/containers/Auth/actions';
import { removeCartCookie } from '../../containers/Cart/helpers';
import { HeaderMobileProfile } from 'eshop-defaults';

interface Props {
  user: any;
  dispatch: (action: any) => void;
}

function HeaderProfile({ user, dispatch }: Props) {
  const handleLogoutUser = () => {
    // dispatch(logoutUser());
    // removeCartCookie();
    // dispatch(resetCart(false));
    // dispatch(fetchCart());

    logoutRedux(dispatch).then(() => {
      // console.log('third');

      setTimeout(() => {
        if (window) {
          window.location.reload();
        }
      }, 500);
    });
  };

  const logoutRedux = async dispatch => {
    await new Promise((resolve, reject) => {
      dispatch(logoutUser(true, () => dispatch(resetCart(false)), '/'));
      removeCartCookie();
      dispatch(resetCart(false));
      // dispatch(fetchCart());
      resolve(null);
    });
  };

  return (
    <Wrapper>
      <DesktopWrapper>
        <LinkLoginWrapper
          to={
            user
              ? __r('routes:moj-ucet', '/moj-ucet')
              : __r('routes:prihlasenie', '/prihlasenie')
          }
        >
          <SvgIcon icon={IconType.user} alt="user-icon" cursor={'pointer'} />
        </LinkLoginWrapper>
        <NameLinkWrapper>
          {user ? (
            <>
              <LinkIconWrapper to={__r('routes:moj-ucet', '/moj-ucet')}>
                <MyAccountText>{__('Môj účet')}</MyAccountText>
              </LinkIconWrapper>
              <LinkLogoutWrapper onClick={handleLogoutUser}>
                <Logout>{__('Odhlásiť sa')}</Logout>
              </LinkLogoutWrapper>
            </>
          ) : (
            <LinkLoginWrapper to={__r('routes:prihlasenie', '/prihlasenie')}>
              <UserName>{__('Prihlásiť sa užívateľ')}</UserName>
            </LinkLoginWrapper>
          )}
        </NameLinkWrapper>
      </DesktopWrapper>
      <MobileWrapper>
        <HeaderMobileProfile handleLogoutUser={handleLogoutUser} user={user} />
      </MobileWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  color: ${({ theme }) => theme.colors.textPrimary};
  margin-right: ${rem(32)};
  height: ${rem(48)};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  transition: color 0.2s ease;
  /* width: ${rem(300)}; */
  
  ${({ theme }) => theme.mediab.l1150`
    margin-right: ${rem(12)};
  `}
`;

const NameLinkWrapper = styled(FlexCol)`
  margin-left: ${rem(8)};

  ${({ theme }) => theme.mediab.l1150`
    display: none;
  `}
`;

const LinkIconWrapper = styled(Link)`
  margin-top: ${rem(4)};
  text-decoration: none;
  &:hover p {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const UserName = styled(TextP)`
  font-size: ${rem(16)};
  font-weight: 500;
  font-family: ${({ theme }) => theme.font.primary};
`;

const MyAccountText = styled(TextP)`
  font-size: ${rem(16)};
  font-weight: 500;
  font-family: ${({ theme }) => theme.font.primary};
  margin-right: ${rem(8)};
`;
const Logout = styled(TextP)`
  font-size: ${rem(14)};
  color: ${({ theme }) => theme.colors.primary};
  width: 100%;
`;
const LinkLoginWrapper = styled(Link)`
  text-decoration: none;
`;
const LinkLogoutWrapper = styled(Link)`
  &:hover p {
    color: ${({ theme }) => theme.colors.textPrimary};
  }
`;

const MobileWrapper = styled(FlexCol)`
  display: none;

  justify-content: center;
  align-items: center;
  white-space: nowrap;
  flex-direction: row;
  gap: 12px;

  ${({ theme }) => theme.mediab.l1150`
    display: flex;
  `}
`;

const DesktopWrapper = styled.div`
  display: block;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  ${({ theme }) => theme.mediab.l1150`
  display: none;
`}
`;

export default HeaderProfile;
